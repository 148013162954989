const getColors = (theme) => ({
    byVariants: {
        info: {
            textColor: theme.palette.primary.dark,
            textColorOnHover: '#FFF',
            backgroundColor: theme.palette.primary.light,
            backgroundColorOnHover: theme.palette.primary.dark,
        },
        archive: {
            textColor: theme.palette.text.secondary,
            textColorOnHover: '#FFF',
            backgroundColor: theme.customColors.button.pill.border,
            backgroundColorOnHover: theme.palette.primary.dark,
        },
        error: {
            textColor: theme.palette.error.main,
            textColorOnHover: '#FFF',
            backgroundColor: theme.palette.error.light,
            backgroundColorOnHover: theme.palette.error.main,
        },
        preview: {
            textColor: theme.palette.primary.dark,
            textColorOnHover: '#FFF',
            backgroundColor: theme.palette.primary.light,
            backgroundColorOnHover: theme.palette.primary.dark,
        },
    },
});
export default getColors;
